/***
TABLE OF CONTENTS
=============================================================================
0. Variables
=============================================================================
***/

/* ==========================================================================
0. Variables
========================================================================== */

$white: #fff;
$smoke-white: #fcfcfc;
$placeholder: #cecece;
$light-grey: #f9f9f9;
$muted-grey: #999;
$title-grey: #a9abac;
$fade-grey: #ededed;
$accent-grey: #ccc;
$dark-text: #4a4a4a;
$chosen-grey: #e0e0e0;
$chosen-dark-grey: #66676b;
$grey-6: #666;
$grey: #ccc;
$section-grey: #fcfcfc;
$light-blue: rgb(94 147 255 / 90%);

$sidebar: #344258;
$red: #ff7273;
$blue: #039be5;
$green: #00b289;
$orange: #eda514;
