@import "../../styles/base.scss";

.footer-wrapper {
    background: #0f0330;
    padding: 4rem 2rem;
    position: relative;
    text-align: center;

    .container {
        display: flex;
        flex-direction: column;

        //Footer logo
        .footer-logo {
            img {
                height: 75px;
            }

            //Brand
            .brand-name {
                color: $white;
                font-size: 1.4rem;
                font-weight: 600;
                letter-spacing: 3px;
                padding: 5px 0;
                text-transform: uppercase;
            }

            //Motto
            .brand-subtitle {
                color: $primary;
                font-size: 0.65rem;
                letter-spacing: 2px;
                text-transform: uppercase;
            }
        }

        //Copyright section
        .copyright {
            color: $primary;
            font-size: 0.85rem;
            position: relative;
            z-index: 2;
        }

        //Coded by section
        .coded-by {
            color: $white;
            font-size: 0.7rem;
            position: relative;
            z-index: 2;

            a {
                color: $secondary;
            }
        }
    }
}
