@import "../../styles/base.scss";

.navbar-wrapper {
    display: flex;
    flex-direction: column;

    #navbar-fluid {
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 100;
        display: flex;
        flex-direction: column;

        .navbar-padding {
            display: none;
        }

        &.is-floating {
            background: linear-gradient(
                to bottom,
                rgba(45,2,117, 1) 0%,
                rgba(45,2,117, 0.85) 60%,
                rgba(45,2,117, 0.5) 80%,
                rgba(45,2,117, 0) 100%
            ) !important;

            .navbar-padding {
                display: flex;
                min-height: 2rem;
            }
        }
    }

    //Navbar
    .navbar {
        background: none !important;
        min-height: 4rem;
        position: relative;
        transition: all 0.3s;
        z-index: 99;

        .container {
            min-height: 4rem;
        }

        //Responsive menu icon
        .navbar-burger {
            height: 4rem;
            width: 4rem;
        }

        //Brand
        .navbar-brand {
            min-height: 4rem;

            img {
                height: 36px;
                max-height: 36px !important;
            }

            //brand text
            .brand-name {
                font-size: 1.2rem;
                font-weight: 600;
                letter-spacing: 3px;
                padding: 0 10px;
                text-transform: uppercase;
            }

            //Removing navbar item default hover behavior
            &:hover {
                .navbar-item {
                    background: transparent !important;
                }
            }
        }

        //Navbar items
        .navbar-item {
            a.button {
                text-transform: uppercase;

                &.hover {
                    font-size: 0.75rem !important;
                    font-weight: 500 !important;
                }
            }

            //Nav link modifier
            &.is-nav-link {
                a {
                    font-size: 0.75rem;
                    text-transform: uppercase;
                }
            }

            a.is-active {
                color: $secondary;
            }
        }

        //Color modifiers
        &.is-light,
        &.is-dark {
            .navbar-brand {
                .brand-name {
                    color: $white;
                }

                .navbar-link::after {
                    border-color: $white;
                }
            }

            .navbar-item {
                a {
                    color: $white;
                }
            }

            .navbar-burger .rotate i {
                background-color: $white !important;
            }

            //Responsive menu
            .navbar-menu {
                &.is-active {
                    position: absolute;
                    width: 100%;
                }
            }
        }

        //Fixed navbar modifier
        &.is-fixed {
            background: $white;
            box-shadow: 0 0 8px 0 rgb(0 0 0 / 12%);
            min-height: 4rem !important;

            a {
                color: $dark-text;

                &:hover {
                    color: $primary;
                }
            }
        }
    }
}
