.canvas-editor-wrapper {
    border: 0.0625rem solid;
    display: flex;
    flex: 1 1 auto;
    flex-flow: column;

    #canvas-editor {
        flex-grow: 1;
    }

    /* Scale canvas with resize attribute to full size */
    canvas[resize] {
        height: 100%;
        width: 100%;
    }
}
