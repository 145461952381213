@font-face {
    font-family: Metropolis;
    font-style: normal;
    font-weight: 100;
    src: url("../../public/resources/fonts/Metropolis-Thin.otf") format("opentype");
}

@font-face {
    font-family: Metropolis;
    font-style: normal;
    font-weight: 200;
    src: url("../../public/resources/fonts/Metropolis-ExtraLight.otf") format("opentype");
}

@font-face {
    font-family: Metropolis;
    font-style: normal;
    font-weight: 300;
    src: url("../../public/resources/fonts/Metropolis-Light.otf") format("opentype");
}

@font-face {
    font-family: Metropolis;
    font-style: normal;
    font-weight: 400;
    src: url("../../public/resources/fonts/Metropolis-Regular.otf") format("opentype");
}

@font-face {
    font-family: Metropolis;
    font-style: normal;
    font-weight: 500;
    src: url("../../public/resources/fonts/Metropolis-Medium.otf") format("opentype");
}

@font-face {
    font-family: Metropolis;
    font-style: normal;
    font-weight: 600;
    src: url("../../public/resources/fonts/Metropolis-SemiBold.otf") format("opentype");
}

@font-face {
    font-family: Metropolis;
    font-style: normal;
    font-weight: 700;
    src: url("../../public/resources/fonts/Metropolis-Bold.otf") format("opentype");
}

@font-face {
    font-family: Metropolis;
    font-style: normal;
    font-weight: 800;
    src: url("../../public/resources/fonts/Metropolis-ExtraBold.otf") format("opentype");
}
