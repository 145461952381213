@import "../../styles/base.scss";

body>#modal-root {
    position: absolute;
    top: 0;
    left: 0;
    width: fit-content;
    height: fit-content;
    z-index: 10000;
}

.modal-wrapper {
    width: 100vw;
    height: 100vh;
    text-align: center;

    .modal-background {
        background-color: rgba(10, 10, 10, 0.5);
    }
}
