@import "../../styles/base.scss";
@import "../../../node_modules/gridstack/dist/gridstack.min.css";

.collaborator-wrapper {
    .grid-stack {
        height: 100%;
        width: 100%;
    }

    .grid-stack-item-content {
        background-color: $light;
    }

    .cm-theme {
        height: 100%;
    }

    .section.collaborator-section {
        height: 100%;
        padding-bottom: 4rem;
        padding-left: 0;
        padding-right: 0;
        padding-top: 4rem;

        .container.collaborator-content {
            min-height: 100vh;
        }

        //Divider
        .divider {
            height: 0.24rem;
            margin: 1rem 0;
            width: 5rem;

            @include primary-gradient-left;

            //Centered modifier
            &.is-centered {
                margin: 1rem auto;
            }

            //Long modifier
            &.is-long {
                width: 8rem;
            }

            //Acts as a spacing element
            &.is-spacer {
                margin-top: 4rem;
            }
        }
    }
}

.session-archived {
    .archive-image {
        padding-top: 1rem;
        padding-bottom: 2rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }
}
