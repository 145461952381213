@import "./styles/base.scss";

* {
    margin: 0;
    padding: 0;
} /* to remove the top and left whitespace */

body {
    font-family:
        Metropolis,
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        "Fira Sans",
        "Droid Sans",
        "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    overflow: hidden;
}

//Common styles
.title,
.subtitle {
    //Light text modifier
    &.is-light {
        color: $white;

        + p {
            color: $white;
        }
    }

    //Semibold modifier
    &.is-semibold {
        font-weight: 600;
    }

    //Thin modifier
    &.is-thin {
        font-weight: 300 !important;
    }

    //Uppercase modifier
    &.is-uppercase {
        text-transform: uppercase;
    }

    //Small spacing modifier
    &.is-tight {
        margin-bottom: 0.625rem !important;
    }

    //Ultra small spacing modifier
    &.is-compressed {
        margin-bottom: 0.3125rem !important;
    }
}

//Titles
.title {
    &.main-title {
        text-shadow: 0.25rem 0.25rem 0.125rem rgb(150 150 150 / 10%);
    }
}

//Subtitles
.subtitle {
    opacity: 0.8;

    &.is-compact {
        margin: 0 auto;
        max-width: 37.5rem;
    }
}
