body>#app-root {
    width: 100vw;
}

.app-wrapper {
    overflow: auto;

    .app-content-wrapper {
        background:
            url(../../../public/resources/img/bg/gradient-left-darker.png) repeat-y top left,
            url(../../../public/resources/img/bg/gradient-right-darker.png) repeat-y top right,
            #1c004c;
        min-height: 100vh;
    }
}
