@import "../../styles/base.scss";

.home-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    section.hero {
        .collaborate-image img {
            width: 70%;
        }

        .hero,
        .section {
            &.is-transparent {
                background: transparent !important;
            }
        }

        .landing-caption {
            margin-left: 2rem;

            .subtitle {
                max-width: 25rem;
            }
        }
    }
}
