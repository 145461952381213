.code-editor-wrapper {
    border: 0.0625rem solid;
    display: flex;
    flex: 1 1 auto;
    flex-flow: column;
    height: 100%;

    #code-editor {
        flex-grow: 1;
        height: 100%;
    }
}
