/* ==========================================================================
0. Color Variables
========================================================================== */

$fullhd-enabled: false;

$primary: #7f00ff;
$primary-accent: #3f00fc;
$secondary: #0f9;
$secondary-accent: #3cf;

/* ==========================================================================
1. Shadow variables
========================================================================== */

//Base shadow
$base-shadow: rgb(0 0 0 / 12%);

//Primary box shadow
$primary-shadow-from: rgb(127 0 255 / 42%);
$primary-shadow-to: rgb(63 0 252 / 20%);
$primary-box-shadow: 0 0.875rem 1.625rem -0.75rem $primary-shadow-from,
    0 0.25rem 1.4375rem 0 $base-shadow,
    0 0.5rem 0.625rem -0.3125rem $primary-shadow-to !important;

//Secondary box shadow
$secondary-shadow-from: rgb(0 255 153 / 42%);
$secondary-shadow-to: rgb(51 204 255 / 20%);
$secondary-box-shadow: 0 0.875rem 1.625rem -0.75rem $secondary-shadow-from,
    0 0.25rem 1.4375rem 0 $base-shadow,
    0 0.5rem 0.625rem -0.3125rem $secondary-shadow-to !important;

.primary-gradient {
    background: $primary;
    background: linear-gradient(to right, $primary, $primary-accent) !important;
}

.secondary-gradient {
    background: $secondary;
    background: linear-gradient(to right, $secondary, $secondary-accent) !important;
}

/* ==========================================================================
2. Gradient mixins
========================================================================== */

@mixin theme-hero-gradient {
    background-image: linear-gradient(to bottom, #5202d8 0%, #3c019e 100%);
}

@mixin primary-gradient-top {
    background: $primary;
    background: linear-gradient(to top, $primary, $primary-accent) !important;
}

@mixin primary-gradient-left {
    background: $primary;
    background: linear-gradient(to left, $primary, $primary-accent) !important;
}

@mixin primary-gradient-right {
    background: $primary;
    background: linear-gradient(to right, $primary, $primary-accent) !important;
}

@mixin secondary-gradient-top {
    background: $secondary;
    background: linear-gradient(to top, $secondary, $secondary-accent) !important;
}

@mixin secondary-gradient-right {
    background: $secondary;
    background: linear-gradient(to right, $secondary, $secondary-accent) !important;
}

@mixin secondary-gradient-left {
    background: $secondary;
    background: linear-gradient(to left, $secondary, $secondary-accent) !important;
}
