@import "./theme.scss";

.button {
    &.k-button {
        font-size: 0.75rem;
        height: 2.5rem;
        min-width: 8.125rem;

        //fat modifier
        &.is-fat {
            padding: 1.625rem 2.5rem !important;
        }

        //If the element is a button rather than a link
        &.is-button {
            line-height: 0;
        }
    }

    //Hover raised modifier
    &.raised:hover {
        box-shadow:
            0 0.875rem 1.625rem -0.75rem rgb(0 0 0 / 42%),
            0 0.25rem 1.4375rem 0 rgb(0 0 0 / 12%),
            0 0.5rem 0.625rem -0.3125rem rgb(0 0 0 / 20%) !important;
    }

    //Uppercase button
    &.upper-button {
        text-transform: uppercase;
    }

    //Rounded buttons
    &.rounded {
        border-radius: 31.25rem;
    }

    //Bold weight
    &.is-bold {
        border-width: 0.125rem;
        font-weight: 500 !important;
        text-transform: uppercase;
    }

    //Slanted button
    &.slanted {
        border: none !important;
        transform: skewX(-18deg);

        .text {
            display: block;
            position: relative;
            transform: skewX(18deg);
            z-index: 3;
        }
    }

    &.k-primary {
        color: $white !important;

        //Gradient modifier
        &.has-gradient {
            border: none !important;
            opacity: 1;
            position: relative;
            transition: all 0.6s ease-out !important;
            z-index: 0;

            @include primary-gradient-top;

            .text {
                display: block;
                opacity: 1 !important;
                position: relative;
                z-index: 3;
            }

            //Hover state
            &:hover {
                .front-gradient {
                    opacity: 0;
                }
            }

            //Gradient front element
            .front-gradient {
                align-items: center;
                border-radius: 0.1875rem;
                display: flex;
                height: 100%;
                justify-content: center;
                left: 0;
                opacity: 1;
                position: absolute;
                top: 0;
                transition: all 0.6s ease-out !important;
                width: 100%;
                z-index: 1;

                @include primary-gradient-right;
            }

            //Rounded modifier
            &.rounded {
                .front-gradient {
                    border-radius: 62.5rem;
                }
            }

            //Raised modifier
            &.raised {
                &:hover {
                    box-shadow: $primary-box-shadow !important;
                    opacity: 0.95;
                }
            }

            //Submit modifier
            &.is-submit {
                &:hover {
                    transform: translateY(-0.625rem);
                }
            }
        }
    }
}
