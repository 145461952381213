@import "../../styles/base.scss";

.loader-wrapper {
    .page-loader {
        background-color: $fade-grey;
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        transform: translateX(100%);
        transform: translateX(100%);
        transition: -webkit-transform 0.35s ease-out;
        transition: transform 0.35s ease-out;
        transition: transform 0.35s ease-out, -webkit-transform 0.35s ease-out;
        width: 100%;
        will-change: transform;
        z-index: 998;

        //Active state
        &.is-active {
            transform: translateX(0);
            transform: translateX(0);
        }

        &.is-theme {
            background-image: linear-gradient(to bottom, #22005b 0%, #161a31 100%) !important;
        }

        &::after,
        &::before {
            border-radius: 100%;
            content: "";
            display: block;
            left: 50%;
            position: absolute;
            top: 50%;
            z-index: 999;
        }

        &::after {
            animation: page-loader-inner 1.5s infinite ease-out;
            animation: page-loader-inner 1.5s infinite ease-out;
            background-color: rgb(255 255 255 / 50%);
            border: 3px solid rgb(255 255 255 / 75%);
            height: 200px;
            margin-left: -100px;
            margin-top: -100px;
            width: 200px;
        }

        &::before {
            animation: page-loader-outer 1.5s infinite ease-in;
            animation: page-loader-outer 1.5s infinite ease-in;
            background-color: #fff;
            height: 60px;
            margin-left: -30px;
            margin-top: -30px;
            width: 60px;
        }
    }

    @keyframes page-loader-inner {
        0%,
        40% {
            transform: scale(0);
            transform: scale(0);
        }

        100% {
            opacity: 0;
            transform: scale(1);
            transform: scale(1);
        }
    }

    @keyframes page-loader-inner {
        0%,
        40% {
            transform: scale(0);
            transform: scale(0);
        }

        100% {
            opacity: 0;
            transform: scale(1);
            transform: scale(1);
        }
    }

    @keyframes page-loader-outer {
        0% {
            transform: scale(1);
            transform: scale(1);
        }

        100%,
        40% {
            opacity: 0;
            transform: scale(0);
            transform: scale(0);
        }
    }

    @keyframes page-loader-outer {
        0% {
            transform: scale(1);
            transform: scale(1);
        }

        100%,
        40% {
            opacity: 0;
            transform: scale(0);
            transform: scale(0);
        }
    }

    .infra-loader {
        background-color: #22005b;
        bottom: 0;
        left: 0;
        opacity: 0;
        position: fixed;
        right: 0;
        top: 0;
        transition: opacity 0.35s;
        width: 100%;
        z-index: -1;

        //Active state
        &.is-active {
            opacity: 1;
            z-index: 997;
        }
    }
}
